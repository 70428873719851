

























































































import { Vue, Component, Prop } from "vue-property-decorator";
import { MainProperties } from "./MainView.vue";
import { ConfirmedOrderProperties } from "./ConfirmedOrderAView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import CustomerOrderService from "@/services/CustomerOrderService";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import ResponseUtil from '@/utilities/ResponseUtil';
import DateUtil from '@/utilities/DateUtil';

const ConfirmedOrderDetailView = () => import("./ConfirmedOrderDetailView.vue");

@Component({
    components: { ConfirmedOrderDetailView },
})
export default class ConfirmedOrderBView extends Vue {
    @Prop() private main: MainProperties;
    @Prop() private properties: ConfirmedOrderProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private customerOrderService = new CustomerOrderService();

    public formatDate(v: any) {
        return v ? DateUtil.format(new Date(v), this.properties.dateTimeFormat) : "";
    }

    public formatOrderStatus(v: any) {
        return this.$t("text." + v.toLowerCase());
    }

    public formatTotalAmount(v: any) {
        const symbol = this.properties.currency.symbol ?? "";
        return symbol + " " + v.toFixed(2);
    }

    public expand(customerOrder: any) {
        Vue.set(customerOrder, "expanded", !(customerOrder.expanded ?? false));
    }

    public async selectionChanged(customerOrder: any) {
        const customerOrders = this.properties.customerOrders;
        var selectAll = customerOrders.length > 0;
        if (customerOrder.selected) {
            var index = customerOrders.indexOf(customerOrder);
            if (index > -1) {
                for (var i = index; i >= 0; i--) {
					const customerOrder = customerOrders[i];
					Vue.set(customerOrder, 'selected', true);
                }
            }
        }

		this.properties.selectAll = customerOrders.every(e => e.selected);
        await this.properties.events.fire("calculate-summary");
    }

    public async created() {
        await this.load();
        this.properties.events.subscribe('load', this.load);
    }

    public destroyed() {
        this.properties.events.remove('load', this.load);
    }

    public async load() {
        this.main.loading = true;

        try {
            const r = await this.customerOrderService.current({
                status: ["To-Pay", "Paid"],
            });
            const customerOrders = r.data.customerOrders;
            this.properties.customerOrders = customerOrders.sort((lhs, rhs) => {
                var n = (lhs.masterNo ?? "").localeCompare(rhs.masterNo ?? "");
                if (n == 0) n = lhs.subNo - rhs.subNo;
                return n;
            });

            this.properties.dateTimeFormat = ResponseUtil.getDateTimeFormat(r);
            await this.properties.events.fire("calculate-summary");
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.main.loading = false;
        }
    }
}
